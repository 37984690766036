import { Radio, Typography } from '@beeline/design-system-react';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PriceInput } from 'redesignSrc/UI/form';
import styles from '../styles.pcss';
import AIStore from '../AIStore';

const LengthText = () => {
  return (
    <div className={styles.radioGroupWrap}>
      <Typography variant="caption">Количество символов в тексте SMS</Typography>
      <div className={styles.radioGroup}>
        <Radio
          id="268"
          label="до 268"
          checked={AIStore.length === 268}
          onChange={() => {
            AIStore.length = 268;
          }}
        />
        <Radio
          id="402"
          label="от 268 до 402"
          checked={AIStore.length === 402}
          onChange={() => {
            AIStore.length = 402;
          }}
        />
        <div>
          <Radio
            id="custom"
            checked={AIStore.isCustomLength}
            onChange={() => {
              AIStore.length = 40;
            }}
            label={
              <div className={styles.input} >
                <PriceInput
                  onBlur={() => {
                    if (AIStore.length < 40) {
                      AIStore.length = 40;
                    }
                  }}
                  placeholder="до 402"
                  value={AIStore.isCustomLength ? AIStore.length.toString() : ''}
                  disabled={!AIStore.isCustomLength}
                  format={false}
                  onChange={(e) => {
                    AIStore.length = Number(e);
                  }}
                  maxNumber={402}

                />
              </div>

            }
          />
        </div>

      </div>
    </div>
  );
};

export default observer(LengthText);
