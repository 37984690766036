import { observable, action, computed, toJS, reaction } from 'mobx';
import { toastAccessNotification, toastErrorNotification } from 'modules/toast-notifications';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import newAudiences from 'store/mobx/NewAudiences';
import newOrder from 'store/mobx/NewOrder';

// types

export type KeyPromts = 'keyInfo' | 'audience' | 'exclusivity' | 'callAction' | 'address' | 'link' | 'phone'

type TypePromt = {
  type: KeyPromts,
  title: string,
  placeholder: string,
  maxLength?: number,

}

// constants

const defaultPromts:TypePromt[] = [
  {
    type: 'keyInfo',
    title: 'Ключевая информация о предложении',
    placeholder: 'Описание вашего товара или услуги',
    maxLength: 200,

  },
  {
    type: 'audience',
    title: 'Целевая аудитория',
    placeholder: 'Кому может быть интересно ваше объявление',
    maxLength: 200,

  },
  {
    type: 'exclusivity',
    title: 'Ограничение по времени или эксклюзивность',
    placeholder: 'Например, скидка или промокод до определенной даты',
    maxLength: 200,
  },
];

const defaultAddPromts:TypePromt[] = [
  {
    type: 'callAction',
    title: 'Призыв к действию',
    placeholder: 'Используйте глаголы, например, «Купите», «Закажи сейчас»',
    maxLength: 200,

  },
  {
    type: 'address',
    title: 'Адрес',
    placeholder: 'Например, Московский переулок, 2',
    maxLength: 200,

  },
  {
    type: 'link',
    title: 'Ссылка для перехода',
    placeholder: 'Например, marketing.beeline.ru/new-campaign',
    maxLength: 200,
  },
  {
    type: 'phone',
    title: 'Телефон, куда могут позвонить клиенты',
    placeholder: 'Например, 88006006262',
  },
];


class AIStore {
  @observable isGenerateText = false
  @observable isActiveModal = false
  @observable length = 268
  @observable keyInfo = ''
  @observable audience = ''
  @observable exclusivity = ''
  @observable callAction = ''
  @observable address = ''
  @observable link = ''
  @observable phone = ''
  @observable prompts:TypePromt[] = defaultPromts
  @observable addPrompts:TypePromt[] = defaultAddPromts

  shortLink = 'https://beel.ink/'

  @action addPromt = (type:KeyPromts) => {
    const currentPromt = this.addPrompts.find(el => el.type === type);

    if (currentPromt) {
      this.addPrompts = this.addPrompts.filter(el => el.type !== type);
      this.prompts.push(currentPromt);
      if (type === 'link' && newAudiences.isSimple && !newOrder.smsCampaign.links.link) {
        const sitesObj = newAudiences.filterAudienceData.find(el => el?.condition?.type === 'Sites');
        if (sitesObj?.condition?.term?.hosts?.length) {
          this.link = sitesObj?.condition?.term?.hosts[0];
        }
      }
    }
  }

  @action reset = () => {
    this.length = 268;
    this.keyInfo = '';
    this.audience = '';
    this.exclusivity = '';
    this.callAction = '';
    this.address = '';
    this.link = '';
    this.phone = '';
    this.prompts = defaultPromts;
    this.addPrompts = defaultAddPromts;
  }

  @action generateText = async () => {
    let promptText = '';
    try {
      this.isGenerateText = true;
      this.prompts.forEach((prompt) => {
        if (this[prompt.type] !== '') {
          const currentPromtType = prompt.type === 'link' ? this.shortLink : this[prompt.type];
          promptText = promptText.concat(`${prompt.title}: ${currentPromtType}.`);
        }
      });

      const req = await axiosBaseRequestAdTech({
        method: 'POST',
        url: '/api/messageTemplates/textGeneration',
        data: {
          campaignId: newOrder.smsCampaign.campaignId,
          length: this.length,
          prompt: promptText,
        },
      });
      toastAccessNotification(req.message);
    } catch (e) {
      toastErrorNotification('Ошибка запроса генерации текста');
      console.error(e);
    } finally {
      this.isGenerateText = false;
    }
  }

  @computed get isCustomLength() {
    return this.length !== 268 && this.length !== 402;
  }
}

const aIStore = new AIStore();

reaction(
  () => newOrder.smsCampaign.links.link,
  () => {
    aIStore.link = newOrder.smsCampaign.links.link || '';
  },
);

export default aIStore;
