import React from 'react';
import styles from './styles.module.pcss';

const CampaignProgressBar = ({
  completed,
  active,
  total,
}: { completed: number, active: number, total: number }) => {
  const currentActive = 100 * active / total;
  const currentCompleted = 100 * completed / total;
  const percentStep = 100 / total;
  return (
    <div className={styles.progressBar}>
      <div className={styles.active} style={{ width: `${currentActive}%` }} />
      <div className={styles.completed} style={{ width: `${currentCompleted}%` }} />
      {
        Array.from({ length: total }).map((dot, idx) => {
          if (idx === 0) return null;
          return <div className={styles.dot} style={{ left: `calc(${(idx * percentStep).toFixed(2)}% - 4px )` }} />;
        })
      }
    </div>
  );
};

export default CampaignProgressBar;
