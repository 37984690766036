import React from 'react';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import { CollapsingBlockWiki, TextPermission, WhiteBlockWiki } from './componentWiki';
import styles from './styles.pcss';

export const AboutWhoAndWhatToWrite = () => {
  return (
    <>
      <CollapsingBlockWiki
        isNotCollaps
      >
        <p className={styles.text}>
          В текстах некоторых рекламных рассылок необходимо указывать дополнительную информацию,
          т.к. этого требует Федеральный закон от 13.03.2006 N 38-ФЗ о ФЗ «Рекламе».
        </p>
        <WhiteBlockWiki
          title="Реклама медицинских услуг, лекарств, методов лечения, косметологии, ветеринарии, оптики"
          content={
            <div>
              <p className={styles.text}>
                Требуются указания о противопоказаниях и консультации со специалистом
              </p>
              <div>
                <TextPermission access="allow">
                  «Успейте в марте воспользоваться первыми весенними скидками: диагностика аллергии - 30% до 31.03.22 предъявителям этого SMS.
                  Ждем Вас: адрес, контакты. Имеются противопоказания, требуется консультация специалиста.»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама БАДов"
          content={
            <div>
              <p className={styles.text}>
                Необходимо указание о том, что БАД «Не является лекарственным средством»
              </p>
              <div>
                <TextPermission access="allow">
                  «Весной поддержите свой иммунитет! До 01.06.22г по промо-коду «SUN» скидка 15%! БАД не является лекарственным средством.»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама страхования, банковских продуктов, финансовых организаций"
          content={
            <div>
              <p className={styles.text}>
                Нужно указывать наименование или имя лица, оказывающего данные услуги (для юридического лица — наименование, для индивидуального предпринимателя — фамилию, имя, отчество)
              </p>
              <div>
                <TextPermission access="allow">
                  «Весна — пора больших расходов! Получите кредит на сумму 200 000руб, процентная ставка 12% годовых. Предъявите паспорт и заберите деньги за 30 минут.
                  Предложение действует до 30.09.22г www... ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП +71231231212»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама от застройщиков"
          content={
            <div>
              <p className={styles.text}>
                Необходимы сведения о месте и способах получения проектной декларации, а также иной подробной информации, и наименование застройщика
              </p>
              <div>
                <TextPermission access="allow">
                  «ООО Наименование застройщика» Доступное жилье от 2,5 млн. руб. Своя инфраструктура. ФЗ-214. Тел: 84951231234 Проектная декларация на сайте наш.дом.рф»
                </TextPermission>
              </div>
            </div>
          }
        />


        <WhiteBlockWiki
          title="Реклама товаров/услуг при дистанционном способе их продажи, интернет-магазинов:"
          content={
            <div>
              <p className={styles.text}>
                Для интернет магазина — наименование юридического лица, ОГРН и место его нахождения.
              </p>
              <p className={styles.text}>
                Для ИП — ФИО и ОГРНИП.
              </p>
              <div>
                <TextPermission access="allow">
                  «ООО Наименование» ОГРН 335528473698, г.Москва, ул.Лесная 9».
                </TextPermission>
                <TextPermission access="none">
                  «ИП Иванов Иван Иванович, ОГРНИП 333030303030303, г.Пермь, ул.Морская 5»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама информационной продукции: приложений, сайтов, программ для ЭВМ, онлайн-игр, зрелищных и культурно-массовых мероприятий, анонс фильмов и мультфильмов, фестивалей, концертов, выставок, ярмарок и пр."
          content={
            <div>
              <p className={styles.text}>
                Необходимо указать знак информационной продукции, примеры обозначений: возрастное ограничение: 0+, 6+, 12+, 16+, 18+
              </p>
              <div>
                <TextPermission access="allow">
                  «Приходите на семейный мюзикл «Кошкин дом» (6+) 12.05.20 в 19:00. Скидка на билеты в партер 20% по промо-коду «ШОУ012». Подробности на сайте www... и по тел. +71231231212» ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама, где есть упоминание кешбэка"
          content={
            <div>
              <p className={styles.text}>
                Требуется указать следующую приписку: «Кешбэк равен Хб-Хр максимальная сумма кешбэка до ХХХ бонусов»
              </p>
              <div>
                <TextPermission access="allow">
                  «Мы знаем, что ищут весной! Заказывайте на сайте (название) до ХХ.ХХ.ХХ и получайте повышенный кешбэк до 10% на все покупки! И бесплатной доставкой. ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП «Кешбэк равен Хб-Хр максимальная сумма кешбэка до ХХХ бонусов»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама МФО, МКК, ККП"
          content={
            <div>
              <p className={styles.text}>
                Требуется указывать все условия займа включая %, пени, или штрафные санкции после акционных периодов              </p>
              <div>
                <TextPermission access="allow">
                  «Успейте взять займ на 30дней за 0% на сумму до 30т.рублей после 1.5% в день. ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП»
                </TextPermission>
              </div>
              <div>
                <TextPermission access="allow">
                  «Успейте взять займ на 30 дней за 0% на сумму до 30т.рублей.
                  ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП «По истечению срока займа 30 дней, наступают иные условия договора займа»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама туров внутри РФ с учётом 20% кешбэка"
          content={
            <div>
              <p className={styles.text}>
                Требуется указать: «20% кешбэк возможно получить при условии оплаты картой платёжной системы МИР»
              </p>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама агрегирующих таксомодальных сервисов и партнёров"
          content={
            <div>
              <p className={styles.text}>
                Подобная реклама должна сопровождаться припиской: ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП
              </p>
              <div>
                <TextPermission access="allow">
                  «Искали подработку в такси? 0% парка первый месяц с сервисом (название). ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП»                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама агрегаторов и сервисов витрин"
          content={
            <div>
              <p className={styles.text}>
                Подобные рекламные компании должны сопровождаться следующей припиской: ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП
              </p>
              <div>
                <TextPermission access="allow">
                  «Выбирай свой лучший займ среди 100+ предложений по ссылке: (сайт). ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП.»
                </TextPermission>
              </div>
              <div>
                <TextPermission access="allow">
                  «Профик.ру найдите того кто поможет Вам от лампочки до вкусного борща (сайт) ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП.»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама юридической компании, нотариусов, адвокатов"
          content={
            <div>
              <p className={styles.text}>
                Подобная реклама должна сопровождаться припиской: ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП. Если адвокат, то в приписке указываете No из реестра адвокатской коллеги              </p>
              <div>
                <TextPermission access="allow">
                  «Юридические консультации по бытовым вопросам! ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП»
                </TextPermission>
              </div>
              <div>
                <TextPermission access="allow">
                  «Больше 20 лет практики в решение сложных вопросов и проблем! Звоните 89ХХХХХХХ. ООО «Наименование» + ИНН либо ОГРНИП в случае если заказчик ИП Адвокатская коллегия, NoХХХХХХХХХХ от 20.01.92г ФИО.»
                </TextPermission>
              </div>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама брокеров и инвестиционных фондов, реклама инвестиций форекс дилеров"
          content={
            <div>
              <p className={styles.text}>
                Подобная реклама должна сопровождаться припиской: «Предлагаемые к заключению договоры или финансовые инструменты являются высокорискованными и могут привести к потере внесенных денежных средств в полном объеме. До совершения сделок следует ознакомиться с рисками, с которыми они связаны»              </p>
            </div>
          }
        />

        <WhiteBlockWiki
          title="Реклама цель которой поиск сотрудников"
          content={
            <div>
              <p className={styles.text}>
                Подобная реклама должна сопровождаться одним из дисклеймеров в зависимости от типа заказчика:
              </p>
              <p className={styles.text}>
                В случае если обратилась сама поисковая площадка:
              </p>
              <div>
                <TextPermission access="allow">
                  «Компания «HeadHunter» не гарантирует наличие подходящих вакансий во всех населённых пунктах РФ, а также негативно относится к дискриминационным требованиям в вакансиях, полная информация на hh.ru»
                </TextPermission>
              </div>
              <p className={styles.text}>
                В случае если клиент ведёт по ссылке на перечень вакансий у себя на сайте:
              </p>
              <div>
                <TextPermission access="allow">
                  «Компания «ХХХХХХХ» не гарантирует наличие подходящих вакансий в указанном городе именно под Вас. Подробнее полная информация на сайте компании.»                </TextPermission>
              </div>
              <p className={styles.text}>
                В случае если клиент ведёт на единственную вакансию у себя на сайте либо сервисе агрегаторе:
              </p>
              <div>
                <TextPermission access="allow">
                  «Компания «ХХХХХХХ» не гарантирует наличие подходящих вакансий для Вас.»
                </TextPermission>
              </div>
            </div>
          }
        />

      </CollapsingBlockWiki>

      <CollapsingBlockWiki
        title="Примечание"
      >
        <p className={styles.text}>
          Настоящая памятка носит информационный характер и не приводит исчерпывающий перечень требований к содержанию SMS сообщений.
          Абонент самостоятельно определяет содержание SMS сообщений и обеспечивает соблюдение требований действующего законодательства Российской Федерации и редакционной политики Билайн при формировании текста SMS сообщений.
        </p>
      </CollapsingBlockWiki>
    </>
  );
};
