import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import styles from './styles.pcss';


export type Props = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  className?: string
  onChangeText?: (e:string)=>void,
  isError?: boolean
};


const PureTextArea:React.FC<Props> = ({ className, onChangeText, isError, ...otherProps }) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    // Устанавливаем начальную высоту textarea после первого рендера
    if (textareaRef.current) {
      textareaRef.current.style.height = '22px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Изменяем высоту textarea в зависимости от содержимого
    if (textareaRef.current) {
      textareaRef.current.style.height = '22px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
    onChangeText?.(e.target.value);
  };

  return (
    <textarea
      ref={textareaRef}
      className={classNames(styles.input, isError && styles.error, className)}
      onChange={handleChange}
      {...otherProps}
      />
  );
};

export default PureTextArea;

