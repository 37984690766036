import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import withError from 'redesignSrc/HOC/withError';
import { formatPrice } from 'utils/formatting';
import styles from './styles.pcss';
import ImgSearch from './accets/search.svg';


type Props = {
  startIcon?: boolean,
  value?: string,
  className?: string,
  onChange?: (e: string) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  name?: string,
  format?: boolean,
  placeholder?: string,
  autoComplete?: string,
  disabled?: boolean,
  max?: number,
  maxNumber?: number,
  isPlusPhone?: boolean
  isPure?: boolean,
  isError?: boolean
}

const PriceInput: React.FC<Props> = ({
  value,
  className = '',
  onChange,
  onFocus,
  onBlur,
  name,
  format = true,
  placeholder,
  autoComplete,
  disabled,
  max,
  startIcon,
  maxNumber,
  isPlusPhone,
  isPure,
  isError,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value.split(' ').join('');
    let plus = '';
    if (text[0] === '+' && isPlusPhone) {
      plus = '+';
      text = text.slice(1, text.length);
    }
    if (text[0] === '-') return;
    if (max && text.length > max) return;
    if (maxNumber && +text > maxNumber) return;
    if (isNaN(Number(text))) return;
    onChange && onChange(`${plus}${text}`);
  };

  const onFocusHandler = () => {
    onFocus && onFocus();
  };

  const onBlurHandler = () => {
    onBlur && onBlur();
  };

  if (isPure) {
    return (
      <input
        disabled={disabled}
        id={name}
        type="text"
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className={classNames(styles.inputPure, isError && styles.error)}
        value={format ? formatPrice(value) : value}
        autoComplete={autoComplete}
      />
    );
  }

  return (
    <div className={classNames(className, styles.holder)}>
      {
        startIcon &&
        <ImgSearch className={styles.icon}/>
      }
      <input
        disabled={disabled}
        id={name}
        type="text"
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className={classNames(styles.input, startIcon && styles.searchActive)}
        value={format ? formatPrice(value) : value}
        autoComplete={autoComplete}
      />
    </div>

  );
};

export default withError(PriceInput);
