import React, { useEffect, useState } from 'react';
import { ContentState, DraftHandleValue, Editor, EditorState, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import classNames from 'classnames/bind';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { format } from 'date-fns';
import { OverlayLoader } from 'components/common/Loaders/components';
import Constructor from 'store/mobx/Constructor';
import { ORDER } from 'redesignSrc/pages/constants';
import NewOrder from 'store/mobx/NewOrder';
import BlurLoader from 'redesignSrc/UI/loaders/BlurLoader';
import SuggestCheckbox from 'redesignSrc/UI/elements/SuggestListWithSearch/SuggestCheckbox';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import SaveTemplateStore from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SaveTemplate/SaveTemplateStore';
import { wordFormByCount } from 'utils/fn';
import StoreT from './storeT';
import {
  decorator,
  deleteMaskLink,
  dropMaskLink,
  isDeleteMask,
  pasteText,
  pastMaskLink,
  pastMaskVariable,
  setFocusAndNewText,
  replaceLinkPromoFromStore,
  isDeleteMaskVariable,
  findTypeIdFromDecoratedText,
} from './utils/utils';
import styles from './styles.pcss';
import {
  MaskLink,
  MaskPromo,
  TypeInitialEditor,
  TypeValue,
  PLACEHOLDER,
  PLACEHOLDER_AI,
  MaskVariable,
  TypeBlockVariables,
  promoForm,
} from './types';
import MessageRemainingLength from './components/MessageRemainingLength';
import AIBlock from './components/AIBlock';
import AIStore from './components/AIBlock/AIStore';


const cx = classNames.bind(styles);


const initialEditorState = ({ link, text, isPromo, promo, variables }: TypeInitialEditor) => {
  let initialText = text || '';
  if (link && initialText.includes(link)) {
    initialText = initialText.replace(link, MaskLink);
  }
  if (isPromo && initialText.includes(promo)) {
    initialText = initialText.replace(promo, MaskPromo);
  }
  if (variables.size !== 0) {
    for (const type of variables.keys()) {
      if (initialText.includes(`[${type}]`)) {
        initialText = initialText.replace(`[${type}]`, `[${type}${MaskVariable}]`);
      }
    }
  }


  initialText = initialText.replace(/\n/gi, ' ').replace(/ {1,}/g, ' ').trim();

  return EditorState.createWithContent(ContentState.createFromText(initialText), decorator);
};

type TypePropsContentEditableLinks = {
  onChange: (e: TypeValue) => void,
  onBlur: () => void,
  error?: string,
  valid: any
}

const ContentEditableLinks: React.FC<TypePropsContentEditableLinks> = ({
  onChange,
  onBlur,
  error,
  valid,
}) => {
  const isTemplate = window.location.href.includes('new-template');
  const isDisable =
    (!isTemplate &&
    NewOrder.smsCampaign.templateId &&
    SaveTemplateStore.template?.status === 'Accepted') ||
    (messageTemplatesStore.formTemplate.values.status === 'Verification' ||
      messageTemplatesStore.formTemplate.values.status === 'Accepted' ||
    AIStore.isActiveModal);
  const [editorState, setEditorState] = React.useState(
    initialEditorState({
      text: isTemplate ?
        messageTemplatesStore.formTemplate.values.template : NewOrder.smsCampaign.message,
      link: StoreT.value.links.link,
      isPromo: NewOrder.smsCampaign.isParameterized || StoreT.variables.has('dynamicPromocode'),
      promo: StoreT.promo,
      variables: StoreT.variables,
    }),
  );
  const [isFocus, setIsFocus] = useState(false);
  const editor = React.useRef<Editor>(null);

  const [onblur, setOnblur] = useState(false);

  useEffect(() => {
    if (!onblur) return;
    setEditorState(EditorState.createWithContent(
      ContentState.createFromText(
        editorState.getCurrentContent().getFirstBlock().getText().replace(/\n/gi, ' ')
          .replace(/ {1,}/g, ' ')
          .trim()), decorator));

    setIsFocus(false);
    onBlur && onBlur();
    setOnblur(false);
  }, [onblur]);

  const handlerFocus = () => {
    setIsFocus(true);
  };

  const handlerBlur = () => {
    setOnblur(true);
  };

  const checkPromo = () => {
    StoreT.oddLoaded = true;
    if (NewOrder.smsCampaign.isParameterized) {
      NewOrder.smsCampaign.isParameterized = false;
    } else {
      StoreT.isOpenPopupPromo = !StoreT.isOpenPopupPromo;
    }
  };
  const removeLastWord = (url: string | null) => {
    if (!url) return '';
    return url.replace(/\/[^/]+$/, '/');
  };
  const checkLink = () => {
    StoreT.oddLoaded = true;
    if (StoreT.linkValue.link) {
      StoreT.resetLinkValue();
    } else {
      StoreT.isOpenPopupLink = !StoreT.isOpenPopupLink;
    }
  };

  // Шаблоны
  useEffect(() => {
    if (isTemplate && StoreT.oddLoaded) {
      if (!messageTemplatesStore.isLoadCheckWords) {
        setEditorState(EditorState.createWithContent(
          ContentState.createFromText(editorState.getCurrentContent().getFirstBlock().getText()), decorator));
      }
    }
  }, [messageTemplatesStore.isLoadCheckWords]);

  useEffect(() => {
    if (!NewOrder.isLoadCheckWords && StoreT.oddLoaded) {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromText(editorState.getCurrentContent().getFirstBlock().getText()), decorator));
    }
  }, [NewOrder.isLoadCheckWords]);

  useEffect(() => {
    if (Constructor.lastTimeTemplateModified && !(isTemplate)) {
      setEditorState(EditorState.createWithContent(ContentState.createFromText(Constructor.currentTextPattern)));
    }
    onBlur && onBlur();
  }, [Constructor.lastTimeTemplateModified]);

  // Добавление ссылки
  useEffect(() => {
    if (StoreT.oddLoaded) {
      const textEditor = editorState.getCurrentContent().getFirstBlock().getText();

      if (StoreT.linkValue.link && !textEditor.includes(MaskLink)) {
        setEditorState(pastMaskLink(editorState, StoreT.linkValue.mask, StoreT.focus));
      } else if (StoreT.linkValue.link && textEditor.includes(MaskLink)) {
        const { originText, text } = replaceLinkPromoFromStore({
          textT: textEditor,
          link: StoreT.linkValue.link as string,
          promo: StoreT.promo,
          isShort: StoreT.linkValue.isShort,
          shortLink: StoreT.linkValue.shortLink,
          dynamicPartLink: StoreT.linkValue.dynamicPartLink,
        });
        StoreT.value.links.isShort = StoreT.linkValue.isShort;
        StoreT.value.text = text;
        StoreT.value.originText = originText;
        onChange && onChange(toJS(StoreT.value));
      } else {
        setEditorState(deleteMaskLink(editorState, StoreT.linkValue.mask));
      }
    }
  }, [StoreT.linkValue.link, StoreT.linkValue.isShort]);

  // Добавление промокода
  useEffect(() => {
    if (StoreT.oddLoaded) {
      if (NewOrder.smsCampaign.isParameterized) {
        if (!StoreT.variables.has('dynamicPromocode')) {
          setEditorState(pastMaskLink(editorState, MaskPromo, StoreT.focus));
        }
      } else {
        setEditorState(deleteMaskLink(editorState, MaskPromo));
      }
    }
  }, [NewOrder.smsCampaign.isParameterized]);

  // Добавление шаблонных переменных
  useEffect(() => {
    if (StoreT.oddLoaded) {
      if (StoreT.currentIdVariables && StoreT.currentIdVariables !== 'dynamicPromocode') {
        const { type } = findTypeIdFromDecoratedText(StoreT.currentIdVariables);
        let focusDrag;
        if (type) {
          focusDrag = StoreT.focusVariables[type];
        }
        if (type === 'discount' || type === 'staticPromocode') {
          const firstId = StoreT.currentIdVariables;
          StoreT.setVariable('date', format(new Date(), 'dd.MM.yy'));
          setEditorState(pastMaskVariable(
            editorState,
            `[${firstId}${MaskVariable}] до [${StoreT.currentIdVariables}${MaskVariable}]`,
            focusDrag || StoreT.focus,
          ));
        } else {
          setEditorState(pastMaskVariable(editorState, `[${StoreT.currentIdVariables}${MaskVariable}]`, focusDrag || StoreT.focus));
        }
      }
      if (StoreT.currentIdVariables === 'dynamicPromocode') {
        StoreT.setVariable('date', format(new Date(), 'dd.MM.yy'));
        setEditorState(pastMaskVariable(
          editorState,
          `${MaskPromo} до [${StoreT.currentIdVariables}${MaskVariable}]`,
          StoreT.focusVariables.staticPromocode || StoreT.focus,
        ));
      }

      if (StoreT.deleteIdVariables === 'dynamicPromocode') {
        setEditorState(deleteMaskLink(editorState, MaskPromo));
      }
      if (StoreT.deleteIdVariables && StoreT.deleteIdVariables !== 'dynamicPromocode') {
        setEditorState(deleteMaskLink(editorState, `[${StoreT.deleteIdVariables}${MaskVariable}]`));
      }
    }
    StoreT.currentIdVariables = null;
    StoreT.deleteIdVariables = null;
  }, [StoreT.variables.size]);

  // Заполнение из шаблона
  useEffect(() => {
    if (!isTemplate && StoreT.oddLoaded) {
      if (SaveTemplateStore.appliedText !== null && StoreT.oddLoaded) {
        NewOrder.smsCampaign.isParameterized = StoreT.variables.has('dynamicPromocode');
        setEditorState(initialEditorState({
          text: SaveTemplateStore.appliedText,
          link: StoreT.value.links.link,
          isPromo: StoreT.variables.has('dynamicPromocode'),
          promo: StoreT.promo,
          variables: StoreT.variables,
        }));
      }
      onBlur && onBlur();
    }
  }, [SaveTemplateStore.appliedText]);


  const addBlockVariable = (type: TypeBlockVariables, focus: number) => {
    if (!StoreDnd.includesId(type)) {
      StoreDnd.setBox(type);
      StoreT.focusVariables[type] = focus;
    }
  };

  const handleDrop = (selectionState: SelectionState): DraftHandleValue => {
    // Блокируем события при применении шаблона
    if (isDisable) return 'handled';
    // Убрать повтрорения!!!!
    if (StoreT.blockDrag?.includes('phone')) {
      if (StoreT.blockDrag === 'phone') {
        addBlockVariable('phone', selectionState.getFocusOffset());
      } else {
        setEditorState(dropMaskLink(editorState, selectionState.getFocusOffset(), `[${StoreT.blockDrag}${MaskVariable}]`));
      }
    }

    if (StoreT.blockDrag?.includes('discount')) {
      if (StoreT.blockDrag === 'discount') {
        addBlockVariable('discount', selectionState.getFocusOffset());
      } else {
        setEditorState(dropMaskLink(editorState, selectionState.getFocusOffset(), `[${StoreT.blockDrag}${MaskVariable}]`));
      }
    }

    if (StoreT.blockDrag?.includes('date')) {
      if (StoreT.blockDrag === 'date') {
        addBlockVariable('date', selectionState.getFocusOffset());
      } else {
        setEditorState(dropMaskLink(editorState, selectionState.getFocusOffset(), `[${StoreT.blockDrag}${MaskVariable}]`));
      }
    }

    if (StoreT.blockDrag?.includes('amount')) {
      if (StoreT.blockDrag === 'amount') {
        addBlockVariable('amount', selectionState.getFocusOffset());
      } else {
        setEditorState(dropMaskLink(editorState, selectionState.getFocusOffset(), `[${StoreT.blockDrag}${MaskVariable}]`));
      }
    }

    if (StoreT.blockDrag === 'staticPromocode') {
      if (StoreT.variables.has('staticPromocode')) {
        setEditorState(dropMaskLink(editorState, selectionState.getFocusOffset(), `[${StoreT.blockDrag}${MaskVariable}]`));
      } else {
        addBlockVariable('staticPromocode', selectionState.getFocusOffset());
      }
    }

    if (StoreT.blockDrag === 'promo') {
      setEditorState(dropMaskLink(editorState, selectionState.getFocusOffset(), MaskPromo));
    }

    if (StoreT.blockDrag === 'link') {
      setEditorState(dropMaskLink(editorState, selectionState.getFocusOffset(), StoreT.linkValue.mask));
    }
    StoreT.blockDrag = null;

    return 'handled';
  };

  const handlePastedText = (text: string, _: string | undefined, es: EditorState): DraftHandleValue => {
    // Блокируем события при применении шаблона
    if (isDisable) return 'handled';
    setEditorState(pasteText(text, es));
    return 'handled';
  };

  const handleChange = (es: EditorState) => {
    // Блокируем события при применении шаблона
    if (isDisable) return;
    const isDeleteTypeVariable = isDeleteMaskVariable(es, editorState, StoreT.variables);
    if (isDeleteTypeVariable) {
      StoreT.variables.delete(isDeleteTypeVariable);
      setEditorState(deleteMaskLink(editorState, `[${isDeleteTypeVariable}${MaskVariable}]`));
      return;
    }

    if (isDeleteMask(es, editorState, StoreT.linkValue.mask)) {
      StoreT.resetLinkValue();
      setEditorState(deleteMaskLink(editorState, StoreT.linkValue.mask));
      return;
    }
    if (isDeleteMask(es, editorState, MaskPromo)) {
      NewOrder.smsCampaign.isParameterized = false;
      setEditorState(deleteMaskLink(editorState, MaskPromo));
      return;
    }
    const currentText = es.getCurrentContent().getFirstBlock().getText();
    const lastText = editorState.getCurrentContent().getFirstBlock().getText();
    const focus = es.getSelection().getFocusOffset();
    StoreT.focus = focus;
    // При фокусе добавляем пробел чтобы можно было вводить текст после ссылки
    if (currentText === lastText && isFocus && currentText.endsWith(StoreT.linkValue.mask)) {
      setEditorState(setFocusAndNewText(focus, `${currentText} `));
      return;
    }
    // При фокусе добавляем пробел чтобы можно было вводить текст после промо
    if (currentText === lastText && isFocus && currentText.endsWith(MaskPromo)) {
      setEditorState(setFocusAndNewText(focus, `${currentText} `));
      return;
    }
    // При фокусе добавляем пробел чтобы можно было вводить текст после шаблонных переменных
    if (currentText === lastText && isFocus && currentText.endsWith(`${MaskVariable}]`)) {
      setEditorState(setFocusAndNewText(focus, `${currentText} `));
      return;
    }
    if (currentText === lastText) {
      StoreT.oddLoaded = false;
    }
    setEditorState(es);
  };

  // Валидация динамических промокодов
  useEffect(() => {
    if (StoreT.oddLoaded) {
      StoreT.errorPromo = null;
      if (!StoreT.filePromo) {
        StoreT.infoPromo = null;
        StoreT.errorPromo = 'Необходимо загрузить файл с уникальными промокодами';
      }
      if (StoreT.filePromo?.declined) {
        StoreT.infoPromo = (
          <>
            <span>{`Мы удалили ${StoreT.filePromo?.declined} ${wordFormByCount(StoreT.filePromo?.declined, promoForm)}, потому что они не соответствуют требованиям`}</span>
            <p style={{ margin: '10px 0 0 0' }}>Количество знаков в промокоде от 3-х до 20-и, только буквы и цифры,
              отсутствие дублей</p>
          </>
        );
      }
      if (StoreT.filePromo && (StoreT.filePromo.rowCount) < NewOrder.countMessage) {
        StoreT.errorPromo = `Недостаточно промокодов в файле. Необходимо ${NewOrder.countMessage} ${wordFormByCount(NewOrder.countMessage, promoForm)} для рассылки. Удалите и загрузите корректный файл`;
      }
      valid.getvalidObj(ORDER.MESSAGE_FIELD);
    }
  }, [NewOrder.countMessage, StoreT.filePromo]);

  // Валидация переменных с датой
  useEffect(() => {
    if (StoreT.oddLoaded && isDisable && NewOrder.currentOrder.startDate) {
      valid.getvalidObj(ORDER.MESSAGE_FIELD);
    }
  }, [NewOrder.currentOrder.startDate]);


  // Отслеживаем редактирование переменных шаблонных для вызова сохранения
  useEffect(() => {
    if (StoreT.oddLoaded && isDisable) {
      onChange && onChange(toJS(StoreT.value));
    }
  }, [StoreT.valueVariables]);


  useEffect(() => {
    const textEditor = editorState.getCurrentContent().getFirstBlock().getText();

    const { originText, text } = replaceLinkPromoFromStore({
      textT: textEditor,
      link: StoreT.linkValue.link as string,
      promo: StoreT.promo,
      isShort: StoreT.value.links.isShort,
      shortLink: StoreT.linkValue.shortLink,
      dynamicPartLink: StoreT.linkValue.dynamicPartLink,
    });
    StoreT.value.text = text;
    StoreT.value.originText = originText;
    if (StoreT.oddLoaded) {
      onChange && onChange(toJS(StoreT.value));
    } else {
      StoreT.oddLoaded = true;
    }
  }, [editorState]);

  useEffect(() => {
    (async () => {
      try {
        await StoreT.uploadFilePromo();
      } catch (err) {
        console.error(err);
      }
    })();
    return () => {
      StoreDnd.clearBox();
    };
  }, []);

  useEffect(() => {
    if (NewOrder.smsCampaign.isOtherOperators) {
      StoreT.value = {
        text: null,
        originText: '',
        links: {
          link: null,
          shortLink: null,
          isShort: false,
          dynamicPartLink: null,
        },
      };
    }
  }, [NewOrder.smsCampaign.isOtherOperators]);

  return (
    <>
      <div className={classNames(styles.wrapLabel, isDisable && styles.disable)}>

        <div className={styles.remainingWrap}>
          <label className={styles.labelText}>Текст SMS</label>
          <MessageRemainingLength
            StoreT={StoreT}
            currentLength={isTemplate ?
              messageTemplatesStore.lengthSms : NewOrder.lengthSms}
          />
        </div>

        {!isTemplate &&
          <SuggestCheckbox
            disabled={AIStore.isActiveModal}
            className={styles.checkBox}
            onChange={checkPromo}
            value={NewOrder.smsCampaign.isParameterized || StoreT.isOpenPopupPromo}
          >
            Промокод
          </SuggestCheckbox>
        }

        <SuggestCheckbox
          disabled={AIStore.isActiveModal}
          isAddLinkCheckbox
          isValidLink={isTemplate ?
            messageTemplatesStore.isValidLink : NewOrder.isValidLink}
          value={StoreT.isOpenPopupLink || !!StoreT.linkValue.link}
          onChange={checkLink}
          className={styles.checkBox}
        >
          Ссылка
        </SuggestCheckbox>


      </div>
      <BlurLoader isLoading={NewOrder.isLoadCheckWords}>
        <div
          className={cx('container', error && 'error', isFocus && 'focus')}
          id={isTemplate ? ORDER.TEMPLATE : ORDER.MESSAGE_FIELD}
        >
          {StoreT.isAI && <div>Мой вариант</div>}
          <div
            onMouseDown={() => editor.current && editor.current.focus()}
            className={styles.containerText}
            >

            <Editor
              stripPastedStyles
              handleDrop={handleDrop}
              handleReturn={() => 'handled'}
              onFocus={handlerFocus}
              handlePastedText={handlePastedText}
              onBlur={handlerBlur}
              ref={editor}
              editorState={editorState}
              onChange={handleChange}
              placeholder={StoreT.isAI ? PLACEHOLDER_AI : PLACEHOLDER}
              blockStyleFn={() => classNames(styles.styleBlock, isDisable && styles.fontColor)}
            />
          </div>

          {StoreT.isAI && <Button
            className={styles.marginButtom}
            startIcon={<Icon size="large" iconName={Icons.Magic}/>}
            variant="overlay"
            onClick={() => {
              AIStore.isActiveModal = true;
            }}
          >
            AI-помошник
          </Button>}

          {AIStore.isActiveModal && <AIBlock/>}

        </div>

      </BlurLoader>
    </>
  );
};

export default observer(ContentEditableLinks);
