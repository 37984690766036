import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import confirmDialog from '../modals/confirmDialog';
import { axiosBaseRequestAdTech } from '../../requests/helpers';
import Bubble from './assets/bubble.svg';
import ModalWheelOfFortune from './modals/ModalWheelOfFortune';
import Store, { PROMO_CAMPAIGN_TYPE } from './store';
import Close from './assets/close.svg';
import styles from './styles.pcss';
import ModalPrise from './modals/ModalPrise';

const firstMay = 'https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/1-%D0%BC%D0%B0%D1%8F2.gif';
const santa = 'https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/santa.gif';


const WheelOfFortune = () => {
  const [isClose, setIsClose] = useState(false);
  const spinWheelOfFortune = async () => {
    const winItem = await confirmDialog(ModalWheelOfFortune);
    if (winItem) {
      const isSpin = await confirmDialog(ModalPrise, { itemPrise: winItem });
      if (isSpin) {
        await spinWheelOfFortune();
      }
    }
  };

  const close = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsClose(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const req = await axiosBaseRequestAdTech({ url: `/api/promo/active?promoCampaignType=${PROMO_CAMPAIGN_TYPE}` });
        if (req && req[0]?.id) {
          Store.promoCampaignId = req[0].id;
        }
        if (Store.promoCampaignId) {
          const reqMaxAttempts = await axiosBaseRequestAdTech({ url: `api/promo/${Store.promoCampaignId}` });
          if (JSON.parse(reqMaxAttempts.model)?.MaxAttempts) {
            Store.maxAttempts = (JSON.parse(reqMaxAttempts.model)?.MaxAttempts);
          }
          Store.items = await axiosBaseRequestAdTech({ url: `/api/promo/${Store.promoCampaignId}/fortunewheel/items` });
          Store.attempts = await axiosBaseRequestAdTech({ url: `/api/promo/${Store.promoCampaignId}/fortunewheel/attempts` });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  if (isClose) return null;
  if (Store.items.length === 0) return null;
  if (Store.attempts === 0) return null;


  return (
    <>
      <div onClick={spinWheelOfFortune} className={styles.wrapper}>
        <div className={styles.container}>
          <img className={styles.img} src={santa} alt="santa"/>
          <Bubble className={styles.bubble}/>
          <Close
            onClick={(e) => close(e)}
            className={styles.close}
          />
        </div>
      </div>
    </>
  );
};

export default observer(WheelOfFortune);
