import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ContentContainer } from 'redesignSrc/UI/layout';
import { GroupTitle } from 'redesignSrc/UI/typography';
import SelectionInput from 'redesignSrc/UI/form/SelectionInput';
import ContractSelectionStore, { TypeBanCustomer } from 'store/mobx/ContractSelectionStore';
import UserInfo from 'store/mobx/UserInfo';
import messageTemplatesStore from 'store/mobx/MessageTemplates';

import { senderNameStore } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import styles from './styles.pcss';


type Props = {
  validation: any;
  description?: string;
  title?: string;
  onChange?: (e: TypeBanCustomer) => void
  isCustomer?: boolean
  isDisable?: boolean,
  selectedValue?: string | null,
};

const ContractSelection: React.FC<Props> = ({
  validation,
  description,
  title = 'Договор',
  onChange,
  isCustomer,
  isDisable,
  selectedValue,
}) => {
  const [value, setValue] = useState(selectedValue || '');

  useEffect(() => {
    ContractSelectionStore.getBans(isCustomer);
    return () => {
      ContractSelectionStore.currentBan = null;
    };
  }, []);

  const onChangeHandler = () => {
    if (ContractSelectionStore.currentBan) {
      setValue(isCustomer ? ContractSelectionStore.currentBan.customerName : ContractSelectionStore.currentBan.ban);
      onChange && onChange(ContractSelectionStore.currentBan);
    }
  };

  useEffect(() => {
    if (ContractSelectionStore.currentBan && !ContractSelectionStore.isClicked && selectedValue) return;
    onChangeHandler();
  }, [ContractSelectionStore.currentBan, ContractSelectionStore.isClicked]);

  if (UserInfo.isCustomerInfoNull && !UserInfo.isRoleSS) return null;

  return (
    <ContentContainer className={styles.container}>
      <GroupTitle className={styles.blockTitle}>{title}</GroupTitle>
      <p className={styles.description}>{description}</p>

      <div className={styles.wrapperInput}>
        <div>
          {
            !selectedValue &&
            <SelectionInput
              value={value}
              validation={validation}
              name="ban"
              disabled={ContractSelectionStore.isDisableBan || isDisable}
            >
              <div className={styles.containerList}>
                {ContractSelectionStore.bans.map((opt) => {
                  return (
                    <div
                      key={opt.ban}
                      className={styles.listElement}
                      onClick={() => {
                        ContractSelectionStore.isClicked = true;
                        validation.setErrorsOfKey('ban', undefined);
                        ContractSelectionStore.currentBan = opt;
                        messageTemplatesStore.isFirstRender = false;
                      }}
                    >
                      <RenderList opt={opt} isCustomer={isCustomer}/>
                    </div>
                  );
                })}
              </div>
            </SelectionInput> ||
            <SelectionInput value={senderNameStore.ban || ''} disabled />
          }

          <RenderInfo currentBan={ContractSelectionStore.currentBan} isCustomer={isCustomer}/>
        </div>

      </div>
    </ContentContainer>
  );
};

const RenderList = ({ opt, isCustomer }: { opt: TypeBanCustomer, isCustomer?: boolean }) => {
  const name = isCustomer ? opt.customerName : `№ ${opt.ban}`;
  const secondaryName = isCustomer ?
    `ID ${opt.customerId}, ИНН ${opt.customerInn}${opt.zone ? `, ${opt.zone}` : ''}` :
    `ID ${opt.customerId}, ${opt.customerName}, ИНН ${opt.customerInn}${opt.zone ? `, ${opt.zone}` : ''}`;

  return (
    <>
      <div>{name}</div>
      <div className={styles.secondary}>{secondaryName}</div>
    </>
  );
};

const RenderInfo = ({ currentBan, isCustomer }: { currentBan: TypeBanCustomer | null, isCustomer?: boolean }) => {
  if (!currentBan) return null;
  const currentText = isCustomer ?
    `ID ${currentBan.customerId}, ИНН ${currentBan.customerInn}${currentBan.zone ? `, ${currentBan.zone}` : ''}` :
    `ID ${currentBan.customerId}, ${currentBan.customerName}, ИНН ${currentBan.customerInn}${currentBan.zone ? `, ${currentBan.zone}` : ''}`;
  return (
    <div className={styles.info}>{currentText}</div>
  );
};


export default observer(ContractSelection);
