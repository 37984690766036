import React from 'react';
import { Typography } from '@beeline/design-system-react';
import PureTextArea from 'redesignSrc/UI/form/PureTextArea';
import { PriceInput } from 'redesignSrc/UI/form';
import styles from '../styles.pcss';

type Props = {
  title: string,
  value?: string,
  onChange?: (e:string)=>void,
  placeholder?: string,
  maxLength?: number,
  isNumber?: boolean,
  isError?: boolean,
}

const PromptField:React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  maxLength,
  title,
  isNumber,
  isError,
}) => {
  const lengthText = value?.length || 0;

  const countText = maxLength ? `(${lengthText}/${maxLength})` : '';

  return (
    <div className={styles.wrapperPromtField}>
      <Typography variant="caption">{`${title} ${countText}`}</Typography>
      {isNumber ?
        <PriceInput
          error={isError}
          isPlusPhone
          placeholder={placeholder}
          value={value}
          format={false}
          onChange={onChange}
          isPure={isNumber}
        /> :
        <PureTextArea
          isError={isError}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChangeText={onChange}
      />
      }

    </div>
  );
};

export default PromptField;
