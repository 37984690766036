// eslint-disable-next-line import/no-unresolved
import { ButtonGroupOption } from '@beeline/design-system-react/types/components/ButtonGroup/ButtonGroup.types';
import { MStatuses, SEGMENT_TYPE } from '../MyAudience/type';
import { TypePriceGroups } from './type';

export const optionsBottomGroupRealTime: [ButtonGroupOption, ButtonGroupOption, ButtonGroupOption] = [
  {
    id: '1',
    label: 'Делают сейчас',
    value: 1,
  },
  {
    id: '2',
    label: 'Находятся рядом',
    value: 2,
  },
  {
    id: '3',
    label: 'Ретаргетинг',
    value: 3,
  },
];

type TypeFirst = {
  segmentBundleIds: number[],
  categoryIds: number[],
  priceGroupIds: (number | null)[],
  priceGroups: TypePriceGroups | null
}

export const getArrBundleCategoryPriceGroupIds = (data: TypePriceGroups | null) => {
  const currentPriceGroupIds1 = data?.widgetBundles.map(el => el.priceGroupId) || [];
  const currentPriceGroupIds2 = data?.widgetCategories.map(el => el.priceGroupId) || [];
  const currentCategoryIds = data?.widgetCategories.map(el => el.categoryId) || [];
  const currentSegmentBundleIds = data?.widgetBundles.map(el => el.segmentBundleId) || [];
  const currentPriceGroupIds = Array.from(new Set(currentPriceGroupIds1.concat(currentPriceGroupIds2)));
  return { currentCategoryIds, currentSegmentBundleIds, currentPriceGroupIds };
};

export const getSegmentOrderPrice = ({
  segmentBundleIds,
  categoryIds,
  priceGroupIds,
  priceGroups,
}: TypeFirst) => {
  const concatSegmentBundleIds: number[] = [...segmentBundleIds];
  const concatCategoryIds: number[] = [...categoryIds];
  const concatPriceGroupIds: (number | null)[] = [...priceGroupIds];
  let price1 = 0;
  let price2 = 0;
  price1 = priceGroups!.widgetBundles.map(el => {
    if (concatSegmentBundleIds.includes(el.segmentBundleId)) return 0;
    if (concatPriceGroupIds.includes(el.priceGroupId)) {
      concatSegmentBundleIds.push(el.segmentBundleId);
      return el.discountPrice;
    }
    concatSegmentBundleIds.push(el.segmentBundleId);
    concatPriceGroupIds.push(el.priceGroupId);
    return el.price;
  }).reduce((a: number, n: number) => a + n, 0);
  price2 = priceGroups!.widgetCategories.map(el => {
    if (concatCategoryIds.includes(el.categoryId)) return 0;
    if (concatPriceGroupIds.includes(el.priceGroupId)) {
      concatCategoryIds.push(el.categoryId);
      return el.discountPrice;
    }
    concatCategoryIds.push(el.categoryId);
    concatPriceGroupIds.push(el.priceGroupId);
    return el.price;
  }).reduce((a: number, n: number) => a + n, 0);


  return price1 + price2;
};

export const isDisabledCreateCampaignButton = (countNumber: number, type: keyof typeof SEGMENT_TYPE, status?: MStatuses) => {
  if (type === 'RealTime' && status === 'Enqueued') {
    return true;
  }
  if (type === 'RealTime' && status === 'Failed') {
    return true;
  }
  if (type === 'RealTime' && status === 'Processing') {
    return true;
  }
  if (type === 'Offline' && countNumber < 10) {
    return true;
  }
  if (type === 'Offline' && status === 'Enqueued') {
    return true;
  }
  if (type === 'Offline' && status === 'Failed') {
    return true;
  }
  if (type === 'Offline' && status === 'Processing') {
    return true;
  }
  return false;
};
