import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Chip, Icon, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { toJS } from 'mobx';
import classNames from 'classnames';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import useValidation from 'redesignSrc/HOC/hooks/useValidation';
import { composeRequiredValidator, composeLinkCorrectValidator, composePhoneValid } from 'utils/fieldValidators';
import AIStore from './AIStore';
import styles from './styles.pcss';
import LengthText from './components/LengthText';
import PromptField from './components/PromptField';

const AIBlock = () => {
  const [error, setErrors] = useState<string | null>(null);
  const rules = {
    keyInfo: [composeRequiredValidator('Заполните поле "Ключевая информация о предложении"')],
    audience: [composeRequiredValidator('Заполните поле "Целевая аудитория"')],
    exclusivity: [composeRequiredValidator('Заполните поле "Ограничение по времени или эксклюзивность"')],
    link: [composeLinkCorrectValidator('Проверьте корректность добавленной ссылки, возможно она содержит опечатки')],
    phone: [composePhoneValid('Номер должен быть в формате +7 ХХХХХХХХХХ или 8 ХХХХХХХХХХ')],
  };
  const valid = useValidation(AIStore, rules);

  const handleGenerateText = async () => {
    const { firstErrorKey } = valid.getAllValid();
    if (firstErrorKey) {
      return;
    }
    await AIStore.generateText();
  };
  useEffect(() => {
    const currentError = Object.values(valid.errors).find(el => !!el);
    setErrors(currentError || null);
  }, [valid.errors]);

  return (
    <GreyContainerPopup
      className={styles.container}
      title="Запрос для генерации"
      onClose={() => {
        AIStore.isActiveModal = false;
      }}
    >
      <span className={styles.description} >Заполните основную информацию и нейросеть напишет рекламное сообщение для вас</span>
      <LengthText/>

      <div>
        <Typography variant="caption">Описание запроса</Typography>
        <div className={classNames(styles.promtsContainer, error && styles.errorBorder)}>
          {AIStore.prompts.map((promptData) => {
            return (
              <PromptField
                isError={!!valid.errors[promptData.type]}
                isNumber={promptData.type === 'phone'}
                key={promptData.type}
                maxLength={promptData.maxLength}
                placeholder={promptData.placeholder}
                title={promptData.title}
                value={AIStore[promptData.type]}
                onChange={(e) => {
                AIStore[promptData.type] = e;
                valid.getvalidObj(promptData.type);
                }}
              />
            );
          })}
        </div>
        <div className={styles.errorText}>{error}</div>
        <div className={styles.addPromtChipWrap}>
          {AIStore.addPrompts.map((promptAdd) => {
            return (
              <Chip
                key={promptAdd.title}
                startAdornment={<Icon iconName={Icons.AddCircled}/>}
                label={promptAdd.title}
                onClick={() => {
                  AIStore.addPromt(promptAdd.type);
                }}
              />
            );
          })}
        </div>
        <div className={styles.buttonAIWrap}>
          <Button
            variant="outlined"
            onClick={AIStore.reset}
           >
            Сбросить
          </Button>
          <Button
            variant="overlay"
            onClick={handleGenerateText}
          >
            Сгенерировать текст SMS
          </Button>

        </div>
      </div>


    </GreyContainerPopup>
  );
};

export default observer(AIBlock);
