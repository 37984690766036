import { action, computed, observable, toJS } from 'mobx';
import { InvoicesType } from 'store/mobx/TSMS/CampaignsTsms/types';
import NewOrder from 'store/mobx/NewOrder';
import UserInfo from 'store/mobx/UserInfo';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import NewAudiences from './NewAudiences';


// "ban": "123493666",
// "companyId": 747,
// "customerId": 2367,
// "customerName": "АО \"РОЛЬФ\"",
// "inn": "5047254063",
// "zone": null,
// "userId": 7683,
// "email": null,
// "managerName": "",
// "isPostPay": false,
// "isUuidVisible": false,
// "isSelfEmployee": false,
// "mainOkved": "21.22.1",
// "regAddress": "Химки, Ленинградское, 21",
// "actualAddress": "Химки, Ленинградское, 21",
// "firstName": "Иван",
// "middleName": "Иванович",
// "lastName": "Иванов",

export type TypeBanCustomer = {
  ban: string,
  companyId: string,
  customerId: number,
  customerName: string,
  inn?: string,
  zone: string | null
  userId?: number,
  email?: string,
  managerName?: string,
  isPostPay?: boolean,
  isUuidVisible?: boolean,
  isSelfEmployee?: boolean,
  mainOkved?: string,
  regAddress?: string,
  actualAddress?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,

  managerFirstName?: string,
  managerMiddleName?: string,
  managerLastName?: string,
  managerId?: number,
  customerInn?: string,
  techCtn?: null,
}


type OptyonsType = {
  ban?: string | null,
  customerId?: number | null,
  industryId?: number | null
}

const normalizeBan = (data: TypeBanCustomer[], isCustomer?: boolean): TypeBanCustomer[] => {
  if (isCustomer) {
    const uniqueIds = new Set();
    const filteredCustomer = data.filter(el => {
      const id = el.customerId;
      if (!uniqueIds.has(id)) {
        uniqueIds.add(id);
        return true;
      }
      return false;
    });
    return filteredCustomer.map(el => ({ ...el, customerInn: el.inn || el.customerInn }));
  }
  return data.map(el => ({ ...el, customerInn: el.inn || el.customerInn }));
};

class ContractSelectionStore {
  @observable bans: TypeBanCustomer[] = [];
  @observable invoices: InvoicesType[] = []
  @observable currentBan: TypeBanCustomer | null = null
  @observable currentInvoice: InvoicesType | null = null
  @observable isNotEffect = false
  @observable isUpdateInvoices = false
  @observable isDelete = false
  @observable isDisableBan = false
  @observable isClicked = false;

  @action getBans = async (isCustomer?: boolean) => {
    const customerId = NewOrder.order?.customerId;
    const urlBan = UserInfo.isRoleSS ? `api/manager/bans?Take=99999&customerId=${customerId}` : 'api/user/bans';

    try {
      this.bans = await axiosBaseRequestAdTech({ url: urlBan }) || [];
      if (!UserInfo.isRoleSS) {
        this.bans = normalizeBan(this.bans, isCustomer);
        if (customerId) {
          this.bans = this.bans.filter(ban => ban.customerId === customerId);
        }
        if (this.bans.length <= 1) {
          this.isDisableBan = true;
        }
      }
      const draftBan = isCustomer ?
        this.bans.find(el => el.customerId === NewAudiences.customerId) :
        this.bans.find(el => el.ban === NewOrder.order?.ban);
      this.currentBan = draftBan || this.bans[0];
    } catch (e) {
      console.error(e);
    }
  }


  @action setInvoiceForOrder = async (invoiceId: number | null) => {
    await axiosBaseRequestAdTech({
      method: 'PUT',
      url: `/api/orders/${NewOrder.order?.id}/invoice?invoiceId=${invoiceId}`,
    });
    this.isDelete = false;
  }

  @action setBanForOrder = async (companyId: string) => {
    if (NewOrder.order?.id) {
      await axiosBaseRequestAdTech({
        method: 'PUT',
        url: `/api/orders/${NewOrder.order.id}/company?companyId=${companyId}`,
      });
      await NewOrder.getOrder(NewOrder.order.id);
    } else {
      console.info('Нет NewOrder.order.id');
    }
  }

  @action setIndustryForOrder = async (industryId: number | null) => {
    if (NewOrder.order?.id) {
      await axiosBaseRequestAdTech({
        method: 'PUT',
        url: `/api/orders/${NewOrder.order?.id}/industry?industryId=${industryId}`,
      });
      this.isDelete = false;
      await NewOrder.getOrder(NewOrder.order.id);
    } else {
      console.info('Нет NewOrder.order.id');
    }
  }

  @action getInvoices = async (opt: OptyonsType) => {
    try {
      this.isUpdateInvoices = true;
      const response = await axiosBaseRequestAdTech({
        url: `/api/invoices?Statuses=1&IsMulti=true${opt.ban ? `&Ban=${opt.ban}` : ''}${opt.customerId ? `&CustomerId=${opt.customerId}` : ''}`,
      });
      this.invoices = response.data;
      if (NewOrder.order?.invoiceId) {
        this.isNotEffect = true;
        this.currentInvoice = this.invoices.find(el => el.id === NewOrder.order?.invoiceId) || null;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isUpdateInvoices = false;
    }
  }
}

const contractSelectionStore = new ContractSelectionStore();

export default contractSelectionStore;

