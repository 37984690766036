import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { InViewPortDetector, PortalWrapper } from 'components/helpers';
import CampaignProgressBar from '../CampaignProgressBar';
import { GroupTitle, LowTitle, MainTitle } from '../../UI/typography';
import styles from './styles.module.pcss';


const CampaignProgress = (
  { step, title, description, totalStep }:
  { step?: number, title?: string, description?: string, totalStep?: number },
) => {
  const [isSticky, setSticky] = useState(false);
  return (
    <>
      <InViewPortDetector detectInLimit={10000} onIntersectOut={() => setSticky(true)} onIntersectIn={() => setSticky(false)}><span /></InViewPortDetector>
      <div className={styles.container} style={{ visibility: isSticky ? 'hidden' : 'visible' }}>
        <GroupTitle className={styles.mainTitle}>{title}</GroupTitle>
        <div className={styles.stepHolder}>
          <LowTitle className={styles.lowTitle}>{description}</LowTitle>
          <div className={styles.steps}>шаг {step} из {totalStep}</div>
        </div>
        {
            step && totalStep &&
            <CampaignProgressBar total={totalStep} active={step} completed={step} />
          }
        <div className={styles.line} />
      </div>

      {
        isSticky &&
        <PortalWrapper>
          <div className={styles.stickyContainer}>
            <div className={styles.sticky}>
              <GroupTitle className={styles.mainTitle}>{title}</GroupTitle>
              <div className={styles.stepHolder}>
                <LowTitle className={styles.lowTitle}>{description}</LowTitle>
                <div className={styles.steps}>шаг {step} из {totalStep}</div>
              </div>
              {
              step && totalStep &&
              <CampaignProgressBar total={totalStep} active={step} completed={step} />
            }
              <div className={styles.line} />
            </div>
          </div>
        </PortalWrapper>
      }
    </>
  );
};

export default observer(CampaignProgress);
