import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
// @ts-ignore
import qs from 'query-string';
import { navigate } from '@reach/router';
import StoreT from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/storeT';
import { replaceVariables } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/utils/utils';
import { TypeBanCustomer } from 'store/mobx/ContractSelectionStore';
import { axiosBaseRequestAdTech } from '../../../requests/helpers';
import { IntolerantWords } from '../NewOrder/type';
import NewOrder from '../NewOrder';
import { SHORT_LINK } from '../../../redesignSrc/pages/PushPages/PushPageSetup/components/PhoneUrlText/helpers';
import { TSMSUserInfo } from '../TSMS/UserInfo';
import {
  defaultValuesTemplateForm,
  defaultValuesTemplateGroupForm, MessageTemplatesComment,
  MessageTemplatesGroup,
  TypeTemplate, TypeTemplateComments,
  TypeTemplateGroup, TypeTemplates,
} from './types';

export type Filter = {
  name: string,
  groups: Array<number>,
  statuses: number[],
  startDate?: string | null,
  endDate?: string | null,
  page: number,
  creators: string[],
  customerId: string
};

type Status = {
  id: number,
  value: string,
  description: string

}

class MessageTemplatesStore {
  @observable isValidLink: boolean = true;
  @observable loading: boolean = false;
  @observable dataCustomer: TypeBanCustomer | undefined
  @observable isFirstRender: boolean = true
  @observable withUrlParameters?: boolean

  @observable id: number = 0;
  @observable isNewTemplatePage: boolean = false;
  @observable status: string | null = null;
  @observable messageTemplatesGroup: MessageTemplatesGroup[] | [] = [];
  @observable messageTemplates: TypeTemplates = {
    templates: [],
    pageCount: 1,
  };
  @observable statuses: Status[] = [];
  @observable statusDescription: { [key: string]: string } = {};
  @observable TEMPLATES_LIMIT: number = 10;
  @observable page: number = 1;
  @observable isLoadingClientBase = false
  @observable showTemplateDocs = false
  @observable showDelTemplate = false
  @observable showTemplateCompany = false
  @observable selectedTemplateId = 0
  @observable showTemplateEditGroup = false
  @observable agreementModal = false
  @observable selectedTemplateGroupId = 0
  @observable totalTemplatesCount = 0

  @observable comments: TypeTemplateComments[] | [] = [];
  @observable comment: MessageTemplatesComment = {
    comment: '',
    type: null,
    createDate: '',
  };

  @observable actualRejectComment: MessageTemplatesComment | null = null;

  @observable filter: Filter = {
    statuses: [],
    page: 1,
    name: '',
    groups: [],
    startDate: undefined,
    endDate: undefined,
    creators: [],
    customerId: '',
  };


  @observable formTemplate: TypeTemplate = {
    values: defaultValuesTemplateForm,
  }
  @observable formTemplateGroup: TypeTemplateGroup = {
    values: defaultValuesTemplateGroupForm,
  }
  @observable messageTemplatesGroupName = 'Без группы';
  @observable currentTemplateId = 0;
  @observable isLoadingPage = true;
  @observable isLoading = false;
  @observable isLoadCheckWords = false
  @observable intolerantWords: IntolerantWords = {
    stopWords: [],
    cautionWords: [],
  }

  @action resetIsTemplatePage = () => {
    this.isNewTemplatePage = false;
  }

  @action resetIntolerantWords = () => {
    this.intolerantWords = {
      stopWords: [],
      cautionWords: [],
    };
  }

  @action resetForm = () => {
    NewOrder.resetIntolerantWords();
    this.formTemplate.values = defaultValuesTemplateForm;
    this.formTemplateGroup.values = defaultValuesTemplateGroupForm;
    this.comments = [];
    this.actualRejectComment = null;
    this.id = 0;
    this.status = null;
    this.messageTemplatesGroupName = 'Без группы';
  }

  @action resetPages = () => {
    this.filter.page = 1;
    this.messageTemplates = {
      templates: [],
      pageCount: 1,
    };
    this.nextPageUrl = `/api/messageTemplates${this.createSearchUrl()}`;
  }

  @action resetFilters = () => {
    this.nextPageUrl = `/api/messageTemplates${this.createSearchUrl()}`;
    this.filter = {
      statuses: [],
      name: '',
      page: 1,
      groups: [],
      startDate: undefined,
      endDate: undefined,
      creators: [],
      customerId: '',
    };
  };

  @action getAllComments = async () => {
    this.isLoading = true;
    try {
      this.comments = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${this.id}/comments`,
      });
      this.actualRejectComment = this.comments
        .filter((comment) => comment.type === 1)
        .sort((a, b) => a.createDate < b.createDate ? 1 : -1)[0] || null;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  @action markAllCommentsRead = async () => {
    await axiosBaseRequestAdTech({
      url: `/api/messageTemplates/${this.id}/comment/read`,
      method: 'POST',
    });
  };

  @action getStatuses = async () => {
    if (this.statuses.length !== 0) return;
    const statuses = await axiosBaseRequestAdTech({
      url: '/api/messageTemplateStatuses',
    });

    runInAction(() => {
      this.statuses = statuses;
      if (!this.statusDescription) {
        this.statusDescription = {};
      }
      statuses.forEach((status: Status) => {
        this.statusDescription[status.value] = status.description;
      });
    });
  };


  createSearchUrl = () => {
    return `?PageSize=${this.TEMPLATES_LIMIT}&${qs.stringify(toJS(this.filter), { skipEmptyString: true })}`;
  }

  @observable nextPageUrl?: string | null = `/api/messageTemplates${this.createSearchUrl()}`;

  @action createTemplateGroup = async () => {
    const queryParam = this.dataCustomer?.customerId ? `?customerId=${this.dataCustomer?.customerId}` : '';
    try {
      this.formTemplate.values.groupId = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/groups${queryParam}`,
        method: 'POST',
        data: {
          name: this.formTemplateGroup.values.name,
          description: this.formTemplateGroup.values.description,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action verificationTemplate = async () => {
    try {
      this.formTemplate.values.groupId = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${this.id}/verification`,
        method: 'POST',
        data: this.status === 'Rejected' ? { text: this.comment.comment } : {},
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action updateTemplateGroup = async (id: number) => {
    try {
      this.formTemplate.values.groupId = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/groups/${id}`,
        method: 'POST',
        data: {
          name: this.formTemplateGroup.values.name,
          description: this.formTemplateGroup.values.description,
        },
      });
      this.messageTemplates.templates = this.messageTemplates.templates.map((template) => {
        if (template.groupId === id) {
          return {
            ...template,
            groupName: this.formTemplateGroup.values.name,
          };
        }
        return template;
      });
    } catch (e) {
      console.error(e);
    } finally {
      // messageTemplatesStore.isLoadingPage = false;
    }
  };

  @action initialCreateTemplate = async () => {
    try {
      this.id = await axiosBaseRequestAdTech({
        url: '/api/messageTemplates',
        method: 'POST',
        data: {},
      });
      this.status = 'Draft';
      await navigate(`/new-template/${this.id}`);
    } catch (e) {
      console.error(e);
    }
  };

  removeLastWord = (url: string | null) => {
    if (!url) return '';
    return url.replace(/\/[^/]+$/, '/');
  }

  findText = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, this.removeLastWord(url));
  }

  findText2 = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, url + this.formTemplate.values.links.dynamicPartLink);
  }

  @action updateTemplate = async () => {
    // if (this.formTemplate.values.links.dynamicPartLink) {
    //   this.formTemplate.values.template = this.findText(this.formTemplate.values.links.link, this.formTemplate.values.template);
    //   this.formTemplate.values.shortTemplate = this.findText(this.formTemplate.values.links.link, this.formTemplate.values.shortTemplate);
    //   this.formTemplate.values.links.link = this.removeLastWord(this.formTemplate.values.links.link);
    // }
    if (StoreT.linkValue.dynamicPartLink) {
      this.formTemplate.values.template = this.findText(this.formTemplate.values.links.link, this.formTemplate.values.template);
      this.formTemplate.values.shortTemplate = this.findText(this.formTemplate.values.links.link, this.formTemplate.values.shortTemplate);
      this.formTemplate.values.links.link = this.removeLastWord(StoreT.linkValue.link);
      this.formTemplate.values.links.dynamicPartLink = StoreT.linkValue.dynamicPartLink;
    }

    try {
      this.currentTemplateId = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${this.id}`,
        method: 'POST',
        data: {
          name: this.formTemplate.values.name,
          description: this.formTemplate.values.description,
          additionalTemplate: this.formTemplate.values.additionalTemplate,
          template: this.formTemplate.values.template,
          groupName: this.formTemplate.values.groupName,
          link: {
            ...this.formTemplate.values.links,
          },
          withUrlParameters: TSMSUserInfo.data?.customerSettings?.isUUIDVisibility ? StoreT.rememberUsers : undefined,
          shortTemplate: this.formTemplate.values.shortTemplate,
          variables: this.formTemplate.values.variables,
          isUnique: this.formTemplate.values.links.isShort,
          customerId: this.dataCustomer?.customerId,

        },
      });
      await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${this.id}/setGroup`,
        method: 'POST',
        data: {
          id: this.formTemplate.values.groupId || null,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingPage = false;
    }
  };

  @action getAllMessageTemplatesGroup = async () => {
    const queryParam = this.dataCustomer?.customerId ? `?customerId=${this.dataCustomer?.customerId}` : '';
    try {
      this.messageTemplatesGroup = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/groups${queryParam}`,
      });
      this.messageTemplatesGroup = [
        ...this.messageTemplatesGroup,
        {
          id: 0,
          name: 'Без группы',
          description: 'Без группы',
          templateCount: 0,
        },
      ];
    } catch (e) {
      // this.isLoading = false;
      console.error(e);
    }
  }

  @action deleteTemplateGroupsById = async (id: number) => {
    try {
      await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/groups/${id}`,
        method: 'DELETE',
      });
      await this.getAllMessageTemplatesGroup();
    } catch (e) {
      console.error(e);
    }
  }

  @action getAllMessageTemplates = async (isDel: boolean = false) => {
    this.isLoading = true;
    try {
      const data = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${this.createSearchUrl()}`,
      });
      runInAction(() => {
        if (this.filter.page === 1) {
          this.messageTemplates.templates = data.templates;
        } else {
          this.messageTemplates.templates = [...this.messageTemplates.templates, ...data.templates];
        }
        this.totalTemplatesCount = data.pageCount;
        if (!data.templates.length) this.nextPageUrl = null;
      });
    } catch (e) {
      this.nextPageUrl = null;
    } finally {
      this.isLoading = false;
    }
  }

  @action getTemplateById = async (id: number) => {
    if (!id || typeof +id !== 'number') return;

    try {
      const template = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${id}`,
      });
      this.status = template.status;
      this.id = template.id;
      this.withUrlParameters = template.withUrlParameters;
      this.status = template.status;
      for (const property in this.formTemplate.values) {
        if (template.hasOwnProperty(property)) {
          // @ts-ignore
          this.formTemplate.values[property] = template[property];
        }
      }
      this.formTemplate.values.links = template.link;

      if (this.formTemplate.values.links.dynamicPartLink) {
        this.formTemplate.values.template = this.findText2(this.formTemplate.values.links.link, this.formTemplate.values.template);
        this.formTemplate.values.shortTemplate = this.findText2(this.formTemplate.values.links.link, this.formTemplate.values.shortTemplate);

        this.formTemplate.values.links.link = this.formTemplate.values.links.link + this.formTemplate.values.links.dynamicPartLink;
      }

      if (template.groupId) {
        const selectedGroup: any = this.messageTemplatesGroup?.find((item: any) => item.id === template.groupId);
        this.messageTemplatesGroupName = selectedGroup.name;
        this.formTemplate.values.groupId = selectedGroup.id;
        this.formTemplate.values.groupName = selectedGroup.name;
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action checkWordsStop = async () => {
    this.isLoadCheckWords = true;
    try {
      const request = await axiosBaseRequestAdTech({
        url: 'api/orders/textCheck',
        method: 'POST',
        data: { text: this.formTemplate.values.template },
      });
      this.intolerantWords = {
        stopWords: request.stopWords,
        cautionWords: request.cautionWords,
      };
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadCheckWords = false;
    }
  }


  @action deleteTemplateById = async (id?: number) => {
    this.loading = true;
    try {
      await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${id}`,
        method: 'DELETE',
      });
      await this.getAllMessageTemplates(true);
      await this.getAllMessageTemplatesGroup();
      this.messageTemplates.templates = this.messageTemplates.templates.filter((item) => item.id !== id);
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.error(e);
    }
  }

  @computed get lengthSms() {
    if (this.formTemplate.values.links.isShort && this.formTemplate.values.shortTemplate && StoreT.variables.has('dynamicPromocode')) {
      return replaceVariables(this.formTemplate.values.shortTemplate || '', StoreT.variables).length - ((SHORT_LINK.length - 1) + StoreT.promo.length);
    }
    if (this.formTemplate.values.links.isShort && this.formTemplate.values.shortTemplate && !StoreT.variables.has('dynamicPromocode')) {
      return replaceVariables(this.formTemplate.values.shortTemplate || '', StoreT.variables).length - (SHORT_LINK.length - 1);
    }
    if (!this.formTemplate.values.links.isShort && this.formTemplate.values.shortTemplate && StoreT.variables.has('dynamicPromocode')) {
      return replaceVariables(this.formTemplate.values.shortTemplate || '', StoreT.variables).length - StoreT.promo.length;
    }
    if (this.formTemplate.values.shortTemplate) {
      return replaceVariables(this.formTemplate.values.shortTemplate || '', StoreT.variables).length;
    }
    return 0;
  }


  getMoreTemplates = async () => {
    if (messageTemplatesStore.messageTemplates.templates.length >= 10) {
      this.filter.page += 1;
      if (this.filter.page <= this.totalTemplatesCount) {
        await this.getAllMessageTemplates();
      }
    }
  }
}

const messageTemplatesStore = new MessageTemplatesStore();

export default messageTemplatesStore;
