import { useCallback, useState } from 'react';
import { observable } from 'mobx';
import { isNullOrUndefined } from 'utils/fn';

export type ERRORS = {
  [key in ERROR_KEY]: string;
};

export type ERROR_KEY = keyof RULES

export type RULES = { [p: string]: ((value: any) => any)[] | any }

export type VALID = {
  getvalidObj: (key: string) => ERRORS;
  errors: ERRORS;
  getAllValid: () => {
    firstErrorKey: null | string;
    currentErrors: ERRORS;
  };
  setErrorsOfKey: (key: string, message: string) => {};
  resetError: () => void;
  setIgnoreKey: (key: string) => string[]
  deleteIgnoreKey: (key: string) => string[]
}


class Store {
  @observable errors: ERRORS = {}
  @observable ignoreKey: string[] = []
}

const useValidation = (data: {}, rules: RULES): VALID => {
  const [store, _] = useState(new Store());

  // Сброс всех ошибок
  const resetError = () => {
    store.errors = {};
  };

  const findValueofKey = (keyFind: string, obj: {}): any => {
    const entries = Object.entries(obj);
    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];

      if (keyFind === key) {
        return value;
      }

      if (typeof value === 'object' && value !== null) {
        const valueTemp = findValueofKey(keyFind, value);
        if (!isNullOrUndefined(valueTemp)) return valueTemp;
      }
    }
    return null;
  };

  const findErrorOfKey = (key: string, err: ERRORS) => {
    const currentValue = findValueofKey(key, data);
    let currentErrors = err;
    if (rules[key]) {
      // @ts-ignore
      for (let i = 0; i < rules[key].length; i++) {
        if (store.ignoreKey.includes(key)) {
          continue;
        }
        // @ts-ignore
        const rule = rules[key][i];
        const errMessage = rule(currentValue);
        if (errMessage) {
          currentErrors = { ...currentErrors, [key]: errMessage };
          break;
        }
        currentErrors = { ...store.errors, [key]: errMessage };
      }
    }
    return currentErrors;
  };

  const findErrorOfKeyforAll = (key: string, err: ERRORS) => {
    const currentValue = findValueofKey(key, data);
    let currentErrors = err;
    if (rules[key]) {
      // @ts-ignore
      for (let i = 0; i < rules[key].length; i++) {
        if (store.ignoreKey.includes(key)) {
          continue;
        }
        // @ts-ignore
        const rule = rules[key][i];
        const errMessage = rule(currentValue);
        if (errMessage) {
          currentErrors = { ...currentErrors, [key]: errMessage };
          break;
        }
        currentErrors = { ...currentErrors, [key]: errMessage };
      }
    }
    return currentErrors;
  };
  // Валидирует ключь в обьекте кроме ignoreKey
  const getvalidObj = (key: string) => {
    const currentError = findErrorOfKey(key, store.errors);
    store.errors = { ...store.errors, ...currentError };
    return currentError;
  };
  // Валидирует все ключи в обьекте кроме ignoreKey
  const getAllValid = () => {
    const keysFields = Object.keys(rules);
    let firstErrorKey: null | string = null;
    let currentErrors = store.errors;
    keysFields.forEach((keyField) => {
      currentErrors = { ...currentErrors, ...findErrorOfKeyforAll(keyField, currentErrors) };
    });
    Object.entries(currentErrors).forEach(([key, value]) => {
      if (!firstErrorKey && value) {
        firstErrorKey = key;
      }
    });
    store.errors = currentErrors;
    return { firstErrorKey, currentErrors };
  };

  // Установка ошибки по ключу
  const setErrorsOfKey = (key: string, message: string) => {
    const newErrors = { ...store.errors, [key]: message };
    store.errors = newErrors;
    return newErrors;
  };

  // Установка игнорируемого ключа
  const setIgnoreKey = (key: string) => {
    if (!store.ignoreKey.includes(key)) {
      store.ignoreKey.push(key);
    }
    return store.ignoreKey;
  };

  // Удаление игнорируемого ключа
  const deleteIgnoreKey = (key: string) => {
    store.ignoreKey = store.ignoreKey.filter(el => el !== key);
    return store.ignoreKey;
  };


  return {
    getvalidObj,
    errors: store.errors,
    getAllValid,
    setErrorsOfKey,
    resetError,
    setIgnoreKey,
    deleteIgnoreKey,
  };
};

export default useValidation;
