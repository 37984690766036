import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { Button } from '@beeline/design-system-react';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import DateSinglePicker from 'redesignSrc/UI/form/NewDatePicker/DateSinglePicker';
import { dateFormatChips } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/utils/utils';
import { addDays } from 'utils/date';
import NewOrder from 'store/mobx/NewOrder';
import StoreT from '../../storeT';
import styles from './styles.pcss';


const DateBlock:React.FC = () => {
  const [value, setValue] = useState<string | null>(StoreT.currentValueVariable.date?.value ?
    format(dateFormatChips(StoreT.currentValueVariable.date?.value as string) as Date, 'yyyy-MM-dd') : null,
  );

  const [oddLoaded, setOddLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    if (oddLoaded) {
      if (value) {
        setIsValid(true);
        setError(null);
      } else {
        setIsValid(false);
      }
    }
    if (NewOrder.currentOrder.startDate && value) {
      if (addDays(new Date(NewOrder.currentOrder?.startDate), 1) > new Date(value)) {
        setIsValid(false);
        setError(`Дата в тексте сообщения не должна быть ранее ${format(new Date(NewOrder.currentOrder?.startDate), 'dd.MM.yy')}`);
      }
    }
    setOddLoaded(true);
  }, [value, NewOrder.currentOrder?.startDate]);

  useEffect(() => {

  });

  const handleSubmit = () => {
    if (StoreT.currentValueVariable.date) {
      StoreT.setVariable(StoreT.currentValueVariable.date.type, format(new Date(value as string), 'dd.MM.yy'));
      StoreT.currentValueVariable.date = null;
    } else {
      StoreT.setVariable('date', format(new Date(value as string), 'dd.MM.yy'));
    }
    StoreDnd.deleteBox('date');
  };

  const onClose = () => {
    StoreT.currentValueVariable.date = null;
    StoreDnd.deleteBox('date');
  };

  const handleOnBlur = () => {
    if (!value) {
      setError('Обязательное поле');
    }
  };
  return (
    <GreyContainerPopup
      title="Введите значение и мы добавим его в SMS"
      onClose={onClose}
    >
      <div className={styles.content}>
        <FormFieldLabel>Дата</FormFieldLabel>
        <DateSinglePicker
          startDatePlaceholder="Выберите дату"
          minDate={new Date()}
          className={styles.wrapDate}
          onChange={(e) => { setValue(e); }}
          valueDateStart={value}
          onBlur={handleOnBlur}
          error={error}
          />
        <div className={styles.buttonContainer}>
          <Button
            variant="overlay"
            size="medium"
            disabled={ !isValid }
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.date ? 'Изменить дату' : 'Добавить в текст'}
          </Button>
          {/* <PrimaryButton
            isGray={!isValid}
            isDisabled={!isValid}
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.date ? 'Изменить дату' : 'Добавить в текст'}
          </PrimaryButton> */}
        </div>

      </div>

    </GreyContainerPopup>
  );
};

export default observer(DateBlock);
